<template>
  <v-dialog v-model="dialog" persistent width="400">
    <v-card class="d-flex flex-column" style="height: 100%">
      <v-card-title
        style="display:flex; flex-direction: row; justify-content:space-between;"
      >
        <span class="headline">Add Manual Time</span>
        <v-btn icon @click="onClose"><v-icon>mdi-close</v-icon></v-btn>
      </v-card-title>
      <v-card-text
        class="pb-0"
        style="align-items: center; display: flex; flex-direction: column;"
      >
        <v-container>
          <v-row>
            <v-col
              cols="12"
              class="d-flex flex-column"
              style="align-items: baseline"
            >
              <label class="app-font-bold mb-5">Date</label>
              <v-menu
                v-model="date_picker"
                :close-on-content-click="false"
                :return-value.sync="pickerDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="startDateStr"
                    readonly
                    solo
                    flat
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                    autocomplete="off"
                    append-icon="mdi-calendar-month-outline"
                    hide-details=""
                    style="width: 100%;"
                  />
                </template>
                <v-date-picker
                  v-model="pickerDate"
                  no-title
                  scrollable
                  show-adjacent-months
                  :max="maxDate"
                >
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="date_picker = false">
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="onSelectStateDate(pickerDate)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column"
              style="align-items: baseline"
            >
              <label class="app-font-bold mb-5">Shift</label>
              <v-select
                v-model="shift"
                :items="responses"
                label="Shift"
                item-text="shift.title"
                item-value="_id"
                style="width: 100%;"
                outlined
                dense
                solo
              />
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-row"
              style="align-items: baseline"
            >
              <v-row>
                <v-col>
                  <label class="app-font-bold mb-5">Start time:</label>
                  <vue-timepicker
                    format="hh:mm a"
                    v-model="startTime"
                    :minute-interval="5"
                  />
                </v-col>
                <v-col>
                  <label class="app-font-bold mb-5">End time:</label>
                  <vue-timepicker
                    format="hh:mm a"
                    v-model="endTime"
                    :minute-interval="5"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              class="d-flex flex-column"
              style="align-items: baseline"
            >
              <label class="app-font-bold mb-5">Memo</label>
              <v-textarea
                v-model="memo"
                rows="5"
                counter="140"
                label="Short description of the work done"
                solo
                outlined
                style="width: 100%;"
                @input="onChange"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-alert dense type="error" v-if="error" class="mx-4">
          {{ error }}
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="onClose">
          Cancel
        </v-btn>
        <v-btn
          color="blue darken-1"
          text
          :loading="loading"
          @click="addTime"
          :disabled="
            !startDateStr ||
              !startTime ||
              !endTime ||
              !shift ||
              !memo ||
              !startTime.a ||
              !startTime.hh ||
              !startTime.mm ||
              !endTime.a ||
              !endTime.hh ||
              !endTime.mm
          "
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-snackbar v-model="snackbar">
      {{ snackMessage }}
    </v-snackbar>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  components: {
    VueTimepicker,
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    volunteer_id: String,
    responses: {
      type: Array,
    },
    onCloseDialog: {
      type: Function,
    },
    onAddedTime: {
      type: Function,
    },
    initDate: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      startDate: null,
      date_picker: false,
      startTime: null,
      endTime: null,
      loading: false,
      snackbar: false,
      snackMessage: null,
      error: null,
      maxDate: "2021-01-01",
      startDateStr: "Dec 31, 2021",
      pickerDate: null,
      today: null,
      memo: "",
      shift: null,
    };
  },
  methods: {
    ...mapActions("timeslot", {
      addManualTime: "addManualTime",
    }),
    onClose() {
      this.memo = "";
      this.startTime = null;
      this.endTime = null;
      this.onCloseDialog();
    },
    onChange() {
      this.error = null;
    },
    addTime() {
      this.error = null;
      this.loading = true;
      this.addManualTime({
        need_id: this.need_id,
        start: `${this.startDateStr} ${this.startTime.hh}:${this.startTime.mm} ${this.startTime.a}`,
        end: `${this.startDateStr} ${this.endTime.hh}:${this.endTime.mm} ${this.endTime.a}`,
        memo: this.memo,
        response_id: this.shift,
        user_id: this.volunteer_id,
      })
        .then((res) => {
          this.loading = false;
          this.memo = "";
          this.onAddedTime(res);
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.snackbar = true;
          this.snackMessage = error.response.data.message;
        });
    },
    convertTZ(date, tzString) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: tzString ? tzString : "America/New_York",
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    onSelectStateDate(dateStr) {
      this.startDateStr = dateFormat(this.convertTZ(dateStr), "mmm d, yyyy");
      this.date_picker = false;
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type",
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
  },
  watch: {
    dialog(newValue) {
      if (newValue) {
        this.startDateStr = dateFormat(this.initDate, "mmm d, yyyy");
      }
    },
  },
  mounted() {
    this.today = this.convertTZ("", this.timezone);
    this.maxDate = dateFormat(this.today, "yyyy-mm-dd");
    this.startDateStr = dateFormat(this.initDate, "mmm d, yyyy");
  },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div >>> .v-dialog {
    margin: 0;
    padding-top: 17px;
    padding-bottom: 17px;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
  div >>> .v-dialog {
    width: 70%;
    max-width: 900px;
    max-height: 80%;
  }
}
</style>
